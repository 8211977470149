@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: #f2f2f2;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
}

h1 {
	color: white;
	font-family: 'Eurostile', sans-serif;
}

h2 {
	color: red;
	font-family: 'Eurostile', sans-serif;
}

h3 {
	color: black;
	font-family: 'Eurostile', sans-serif;
}

h4 {
	color: grey;
	font-family: 'Eurostile', sans-serif;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 10px;
  border: none;
}

td:first-child {
  width: 30%;
}

td:last-child {
  border-bottom: none;
  text-align: center;
}

td:last-child span {
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
}
.footer {
  background-color: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  margin-top: auto;
}

.footer hr {
  width: 80%;
  margin: 10px 0;
  border: none;
  border-top: 1px solid #fff;
}

.footer p {
  font-size: 12px;
  margin: 0;
  text-align: center;
}

.footer img {
  width: 200px;
  margin-top: 10px;
}
